<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      {{ item.tracking_id }}
    </v-card-title>

    <v-card-subtitle>
      {{ $t("labels.change_model") }}
    </v-card-subtitle>

    <v-card-text>
      <v-autocomplete
        class="c-input-small"
        v-model="leadModel"
        :items="leadModelOptions"
        :label="$t('labels.model')"
        outlined
        dense
        hide-details
        single-line
      ></v-autocomplete>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="changeModel">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { LEAD_MODELS } from "@/libs/const";

export default {
  name: "LeadChangeModel",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    leadModel: null,
    leadModelOptions: [...LEAD_MODELS],
  }),
  created() {
    this.leadModel = this.item.process_model;
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },

    async changeModel() {
      if (this.leadModel == this.item.process_model) {
        return false;
      }

      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/crm-lead-change-model", {
          id: this.item.id,
          process_model: this.leadModel,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData", true);
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
